import React, { useState } from "react"
import { Background, Parallax } from "react-parallax"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Grid, Row, Col } from 'react-bootstrap'
import { graphql } from 'gatsby'
import StrataDocumentsModal from "../documents/strata-documents-modal"
import TitleHeader from "../../components/title-header"

const ResidentialPage = () => {
  const [currentForm, setCurrentForm] = useState("");
  const rentalManagementProposalRequest = 'rentalManagementProposalRequest'
  const rentalManagementProposalRequestUrl = 'https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/11'
  return (
    <Layout>
      <SEO title="Residential Property Management | Korecki Real Estate Services" description="Residential Property Management with Korecki Real Estate Services provides you with many benefis including but not limited to: Free market rent evaluation, Provide 24/7 emergency response to tenants and landlords, Prepare monthly financial statements" keywords="korecki,Real Estate,Strata Management services, Rent, Properties, Property Management, Client Property Account System, CPAS, Property Information"/>
      <div>
        <TitleHeader title={"Rental Management Services"}/>
        {/*The following code is not from contentful*/}
        <Col md={12} className="blocktext" style={{ backgroundColor: 'rgba(250,250,250)'}}>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <p className="no-h middle justified-black">Korecki Real Estate Services is a
                locally owned and operated Rental Management Company. Technology and online accessibility form the backbone of our operations, with a personal
                touch and direct communication remaining at the forefront. We administer all building
                operations from our Vancouver office and pride ourselves on providing the same
                business days responses to all inquiries.
                <br />
                <br />
                Please see below a brief outline of our services and operating procedures.</p>
            </Row>
          </section>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <h1 className="text-uppercase font-weight-bold small-h1 middle">Transitions</h1>
              <p className="text-md text-color-dark"></p>
            </Row>
            <Row className="custom-sm-flex-order">
              <p className="no-h middle justified-black">Accurate record-keeping and understanding
                of building operations are crucial to any successful transition. Therefore, the Transitions
                Team works closely with all stakeholders to index new rental records and implement standard
                operating procedures. The process facilitates a smooth transition and forms a basis for
                continued building operations.</p>
            </Row>
          </section>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <h1 className="text-uppercase font-weight-bold small-h1 middle">Technology</h1>
              <p className="text-md text-color-dark"></p>
            </Row>
            <Row className="custom-sm-flex-order">
              <p className="no-h middle justified-black">As a future-ready, paperless company,
                we utilize a suite of products to ensure timely access to information, collaborative team
                communications, and tracking of ongoing tasks and directives.</p>
            </Row>
          </section>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <h4 className="text-uppercase font-weight-bold small-h2 middle-subsection">
                <img alt="Yardi Voyager" width="35px" src={require('../../images/yardi.png')}/> Yardi Voyager
              </h4>
              <p className="text-md text-color-dark"></p>
            </Row>
            <Row className="custom-sm-flex-order">
              <p className="no-h middle-subsection justified-black">Using Yardi Voyager, an industry-leading
                accounting and rental management software, we consolidate our accounting, operations, and
                maintenance management on a mobile online platform.</p>
            </Row>
          </section>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <h4 className="text-uppercase font-weight-bold small-h2 middle-subsection">
                <img alt="Yardi Voyager" width="35px" src={require('../../images/account.png')}/> CLIENT PROPERTY ACCOUNT SYSTEM
              </h4>
              <p className="text-md text-color-dark"></p>
            </Row>
            <Row className="custom-sm-flex-order">
              <p className="no-h middle-subsection justified-black">Using our custom-built rental management
                platform, we provide owners with online access to all property records including budget breakdowns
                and maintenance calendars.</p>
            </Row>
          </section>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <h4 className="text-uppercase font-weight-bold small-h2 middle-subsection">
                <img alt="monday.com" width="50px" src={require('../../images/monday-logo.png')} /> Monday.com
              </h4>
              <p className="text-md text-color-dark"></p>
            </Row>
            <Row className="custom-sm-flex-order">
              <p className="no-h middle-subsection justified-black">Using Monday.com project management
                software, we track all ongoing building objectives and owner directives. Thereby ensuring
                continuity of work regardless of vacations and absences.</p>
            </Row>
          </section>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <h4 className="text-uppercase font-weight-bold small-h2 middle-subsection">
                <img alt="monday.com" width="70px" src={require('../../images/slack.jpg')}/> Slack
              </h4>
              <p className="text-md text-color-dark"></p>
            </Row>
            <Row className="custom-sm-flex-order">
              <p className="no-h middle-subsection justified-black">Using Slack messaging,
                our team harnesses the power of a hivemind. From internal communications to advice on
                operational issues and after-hours emergencies, team members draw on the expertise of
                the entire organization day or night.</p>
            </Row>
          </section>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <h4 className="text-uppercase font-weight-bold small-h2 middle-subsection">
                <img alt="monday.com" width="55px" src={require('../../images/sharepoint.jpg')} /> Microsoft Sharepoint
              </h4>
              <p className="text-md text-color-dark"></p>
            </Row>
            <Row className="custom-sm-flex-order">
            <p className="no-h middle-subsection justified-black">Using Microsoft SharePoint and
              SharePoint Workflows, we ensure the accurate filing of property records and workflows
              for all document types. From tenant repair request, to invoice approvals and contract filing,
              we have the required workflows to ensure proper compliance and oversight.</p>
            </Row>
          </section>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <h4 className="text-uppercase font-weight-bold small-h2 middle-subsection">
                <img alt="monday.com" width="55px" src={require('../../images/cloud-logo.png')} /> CLOUDS, PHONES & VPN’s
              </h4>
              <p className="text-md text-color-dark"></p>
            </Row>
            <Row className="custom-sm-flex-order">
              <p className="no-h middle-subsection justified-black">As a fully digital company,
                we utilize cloud storage, VOIP phones, and VPN network access. These services allow our
                team to work seamlessly from home or the office while providing our senior team with
                top-level oversight.</p>
            </Row>
          </section>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <h4 className="text-uppercase font-weight-bold small-h2 middle-subsection">
                <img alt="monday.com" width="60px" src={require('../../images/lock.png')} /> Security
              </h4>
              <p className="text-md text-color-dark"></p>
            </Row>
            <Row className="custom-sm-flex-order">
              <p className="no-h middle-subsection justified-black">Data security is our top
                priority. All data is stored within Canada and encrypted at industry standards. Our
                network infrastructure is designed and managed by local professionals who continuously
                monitor it for threats and intrusions.</p>
            </Row>
          </section>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <h1 className="text-uppercase font-weight-bold small-h1 middle">Standard Operating Procedures</h1>
              <p className="text-md text-color-dark"></p>
            </Row>
            <Row className="custom-sm-flex-order">
              <p className="no-h middle justified-black">No two rental properties are alike,
                but they are all bound by: The Residential Tenancy Act, the Personal Information Protection
                Act, the Real Estate Services Act, and the ever-evolving Civil Resolution Tribunal/Supreme
                Court of BC decisions. As a management company, we have developed rigorous operating
                procedures ensuring that our clients comply with the individual acts and provisions.
                <br />
                <br />
                All repetitive and standard administrative/accounting tasks have natural workflows,
                eliminating the need for owner reminders or follow-ups. The process provides a
                stress-free management experience with the expertise guidance of our Agents.</p>
            </Row>
          </section>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <h1 className="text-uppercase font-weight-bold small-h1 middle">Trades & Service Providers</h1>
              <p className="text-md text-color-dark"></p>
            </Row>
            <Row className="custom-sm-flex-order">
              <p className="no-h middle justified-black">We understand current labor markets and
                work with local arms-length vendors. We are utilizing economies of scale to obtain the best-priced
                quality vendors/services while adhering to regimented screening and bidding processes to
                secure multiple bids wherever possible.</p>
            </Row>
          </section>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <h1 className="text-uppercase font-weight-bold small-h1 middle">Emergency Response</h1>
              <p className="text-md text-color-dark"></p>
            </Row>
            <Row className="custom-sm-flex-order">
              <p className="no-h middle justified-black">We guarantee a 24/7 15-minute response
                time to building or unit emergencies, provided by our local on-call Agents. The Agents are
                skilled at determining the severity of emergencies and dispatching necessary vendors.
                In addition, the Agents utilize building/unit-specific info sheets to dispatch trades and
                connect to our entire workforce through our internal slack messaging service.</p>
            </Row>
          </section>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <p className="no-h middle justified-black">We understand that changing management
                companies is a significant decision, and we welcome the opportunity to discuss how we can
                improve your property operations.
                <br />
                <br />
                Contact us today for a no obligations Rental Management Proposal.</p>
            </Row>
          </section>
          <section className="last-section background ">
            <div className="row center">
              <Row className="pt-4">
                <button
                  className="btn btn-outline btn-primary-1 text-uppercase parallax-button"
                  onClick={() => setCurrentForm(rentalManagementProposalRequest)}>
                  Rental Management Proposal Request
                  <StrataDocumentsModal
                    open={currentForm===rentalManagementProposalRequest}
                    openModal={setCurrentForm}
                    documentUrl={rentalManagementProposalRequestUrl}/>
                </button>
              </Row>
            </div>
          </section>
        </Col>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
     allContentfulResidentialSection (
       sort: { fields: [order, residentialServices___order], order: ASC }
       ) {
        edges {
          node {
            order
            title
            notes
            residentialServices {
              title
              order
              notes {
                notes
              }
              featureList
              image {
                file {
                  url
                }
              }
            }
          }
        }
     }
  }
  `
export default ResidentialPage
